@import '../../../../scss/theme-bootstrap';

.category-links-block-formatter {
  margin: 0 auto;
  max-width: 1281px;
  width: 100%;
  // font-size: 40px;
  // font-size: 64px; med up
  &__content {
    font-family: $ano-bold_regular-font;
    max-width: 100%;
    padding: 20px 0 0 0;
    overflow: hidden;
    @include breakpoint($bp--large-up) {
      padding: 20px 0 0 0;
    }
  }
  // @todo finalize
  &__content-inner {
    overflow: visible;
    font-size: 40px;
    line-height: 1;
    text-align: center;
    // calculate rows and heights based on what we set in the .inc file
    // per pc or mobile font size
    @include breakpoint($bp--large-down) {
      @for $i from 1 through 12 {
        $height: 40px * $i - 2;
        &.rows-mobile--#{$i} {
          max-height: $height;
        }
      }
    }
    @include breakpoint($bp--large-up) {
      font-size: 64px;
      @for $i from 1 through 4 {
        $height: 64px * $i - 2;
        &.rows-pc--#{$i} {
          max-height: $height;
        }
      }
    }
  }
  .homepage-formatter & {
    .block-eyebrow--header,
    .block-headline--header {
      margin-#{$ldirection}: 36px;
    }
  }
}
